'use strict'

###*
 # @ngdoc service
 # @name lpaData.factory:AppSettings

 # @description

###
angular
  .module 'lpaData'
  .factory 'AppSettings', [
      'Restangular'
      (Restangular) ->
        Restangular.service('settings')
    ]
